import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { ChainId } from 'pancake-sdk'

export const getAddress = (address: Address): string => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string, 10)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMulticallAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.Multicall[chainId] : getAddress(addresses.Multicall)
}


export const getCatmeTokenAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.CatmeToken[chainId] : getAddress(addresses.CatmeToken)
}

export const getStakingAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.Staking[chainId] : getAddress(addresses.Staking)
}


export const getCatStakingAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.CatStaking[chainId] : getAddress(addresses.CatStaking)
}


export const getUsdtTokenAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.UsdtToken[chainId] : getAddress(addresses.UsdtToken)
}


export const getBurnCatAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.BurnCat[chainId] : getAddress(addresses.BurnCat)
}

export const getNftMintAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.nftMint[chainId] : getAddress(addresses.nftMint)
}

export const getLpStakingAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.lpStaking[chainId] : getAddress(addresses.lpStaking)
}

export const getLpTokenAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.lpToken[chainId] : getAddress(addresses.lpToken)
}

export const getLpPeipeiStaking1Address = (chainId?: ChainId) => {
  return  chainId ? addresses.lpPeipeiStaking1[chainId] : getAddress(addresses.lpPeipeiStaking1)
}

export const getLpPeipeiStaking2Address = (chainId?: ChainId) => {
  return  chainId ? addresses.lpPeipeiStaking2[chainId] : getAddress(addresses.lpPeipeiStaking2)
}

export const getLpPeipeiStaking3Address = (chainId?: ChainId) => {
  return  chainId ? addresses.lpPeipeiStaking3[chainId] : getAddress(addresses.lpPeipeiStaking3)
}
