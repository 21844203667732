export default {
  Multicall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  Staking: {
    97: '0x7D8dcd43Cd5C5e0AB0E5F5aC3ab807EE5f6B8B57',
    56: '0x0bD0d4E6473AF37E87367FFFE4303Db4C7420Af5',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  CatStaking: {
    97: '0x7D8dcd43Cd5C5e0AB0E5F5aC3ab807EE5f6B8B57',
    56: '0x0eC06cB245E85F46385d46D5c56dF53b974482C3',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  CatmeToken: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0xB71b42f7F0513b3f49a0A42FfDcf90509A888888', // '0xB71b42f7F0513b3f49a0A42FfDcf90509A888888',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  BurnCat: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0xCA480130D6F7a70a25246Ab0b1a9873091801ed7', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  // 改
  UsdtToken: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x55d398326f99059fF775485246999027B3197955', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  // 改
  nftMint: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x8d43508E7D7566085E90d0C8C5202a387B2ecda7', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  // 改
  lpStaking: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0xb1Fd1058421773645B9878f36Cb8D5762F09f431', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  // 改
  nftCatme: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0xA59C389B539256a1cf80e3b6ae7eF65579bF15C1', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  lpToken: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x0654C33FB3021Eeabf2725bdc1533FaF02DC9743', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  lpPeipeiStaking1: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x523afea661801d976BceE092D87b878ceb40CCC9', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  lpPeipeiStaking2: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x4c25ecB25fe37d94e67444e4Bd68f3A3636D2BC7', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  }
  ,
  lpPeipeiStaking3: {
    97: '0x3CD0eb169750168AD054c3D6cC567177bc19d1FE',
    56: '0x14e2e53cc93F1B1076C75723dE8DFF578BCf8502', // '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  }
}
