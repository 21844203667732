import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import {
  useBurnCatContract,
  useERC20Contract,
  useLpPeipeiStakingContract,
  useLpPeipeiStakingV2Contract,
  useLpStakingContract,
  useNftMintContract,
} from 'hooks/useContract'
import { ethers, utils } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import { getUsdtTokenAddress } from 'utils/addressHelpers'
import { message } from 'antd'
import { match } from 'utils/match'
import {
  fetchALLLpStakingData,
  fetchALLLpStakingUserData,
  fetchLpStakingUserData,
  setLpStakingData,
  setLpStakingUserData,
} from 'state/lpStaking'

export const useWithdraw = (contractAddress: string) => {
  const { chainId, account, library } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useLpPeipeiStakingContract(contractAddress)

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      const gasPrice = await library.getGasPrice()
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'withdraw', [], 1000)
      const response = await stakingContract.withdraw({ gasLimit: gasLimit, gasPrice })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchALLLpStakingData(chainId)
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, stakingContract,library, account])

  return { isLoading, withdraw }
}
export const useLockPositionV2 = (contractAddress: string, value: string, ref: string) => {
  const { chainId, account ,library} = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useLpPeipeiStakingV2Contract(contractAddress)

  // 加密数据
  const lockPosition = useCallback(async () => {
    /* eslint-disable */
    try {
      const gasPrice = await library.getGasPrice()
      setLoading(true)
      const gasLimit = await estimateGas(
        stakingContract,
        'lockPosition',
        [ref || ethers.constants.AddressZero, {value: utils.parseEther(`${value}`)}],
        1000
      )
      const response = await stakingContract.lockPosition(
        ref || ethers.constants.AddressZero,
        { gasLimit: gasLimit, value: utils.parseEther(`${value}`) , gasPrice}
      )

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchALLLpStakingData(chainId)
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('Deposit success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId,library, stakingContract, value, ref, account])

  return { isLoading, lockPosition }
}

export const useLockPosition = (contractAddress: string, value: string, ref: string) => {
  const { chainId, account ,library} = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useLpPeipeiStakingContract(contractAddress)

  // 加密数据
  const lockPosition = useCallback(async () => {
    /* eslint-disable */
    try {
      const gasPrice = await library.getGasPrice()
      setLoading(true)
      const gasLimit = await estimateGas(
        stakingContract,
        'lockPosition',
        [utils.parseEther(`${value}`), ref || ethers.constants.AddressZero],
        1000
      )
      const response = await stakingContract.lockPosition(
        utils.parseEther(`${value}`),
        ref || ethers.constants.AddressZero,
        { gasLimit: gasLimit, gasPrice }
      )

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchALLLpStakingData(chainId)
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('Deposit success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId,library, stakingContract, value, ref, account])

  return { isLoading, lockPosition }
}

export const usetUsdtApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getUsdtTokenAddress(chainId))

  // 加密数据
  const approve = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, contract,  account])

  return { isLoading, approve }
}

export const useLpApprove = (contractAddress, sender) => {
  const { chainId, account, library } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(contractAddress)


  // 加密数据
  const lpApprove = useCallback(async () => {
    /* eslint-disable */
    try {
      const gasPrice = await library.getGasPrice()
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit , gasPrice})
      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, contract,library,  account])

  return { isLoading, lpApprove }
}

export default {}
