import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BigNumber, ethers, utils } from 'ethers'
import { getLpStakingAddress, getLpPeipeiStaking1Address, getLpPeipeiStaking2Address, getLpPeipeiStaking3Address } from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import lpStakingV2_abi from 'config/abi/LpPeipeiStakingV2.json'
import lpStaking_abi from 'config/abi/LpPeipeiStaking.json'
import { Call, multicall } from 'utils/calls/multicall'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchLpStakingData = async (chainId: any = NETWORK_CHAIN_ID, address: string, name: string, dec: number): Promise<any> => {
  const lpStakingCalls = [
    'startTradeBlock',
    'maxProfitMultiplier',
    'releaseRatio',
    'TotalBnbAmountProfitMultiplier',
    'TotalLockLpAmount',
    'TotalClaimLp',
    'TotalLockLpBnbAmount',
    'TotalHastenBnbAmount',

    'TotalRewardBnbAmount',
    'TotalEntryBnbAmount',
    'TotalLockMainAmount',
    '_mainBnbPrice',
    'owner',
    'TotalClaimReward',
    'isClose',
    'mainToken',
    '_payBnbPrice',
    '_getMainReserves',
  ].map((method) => {
    return {
      address,
      name: method,
      params: [],
    }
  })
  // 创建合约对象
  const result = await multicall(chainId, lpStakingV2_abi, lpStakingCalls)

  const [
    [startTradeBlock],
    [maxProfitMultiplier],
    [releaseRatio],
    [TotalBnbAmountProfitMultiplier],
    [TotalLockLpAmount],
    [TotalClaimLp],
    [TotalLockLpBnbAmount],
    [TotalHastenBnbAmount],
    [TotalRewardBnbAmount],
    [TotalEntryBnbAmount],
    [TotalLockMainAmount],
    [_mainBnbPrice],
    [owner],
    [TotalClaimReward],
    [isClose],
    [uniswapPair],
    [_payBnbPrice],
    _getMainReserves
  ] = result

  return {
    staking: {
      name,
      owner,
      isClose,
      dec,
      reserveA: _getMainReserves[0].toString(),
      reserveB: _getMainReserves[1].toString(),
      uniswapPair: uniswapPair.toString(),
      startTradeBlock: Number(startTradeBlock.toString()),
      maxProfitMultiplier: Number(maxProfitMultiplier.toString()),
      releaseRatio: Number(releaseRatio.toString()),
      TotalBnbAmountProfitMultiplier: utils.formatEther(TotalBnbAmountProfitMultiplier.toString()),
      TotalLockLpAmount: utils.formatUnits(TotalLockMainAmount.toString(), dec),
      TotalClaimLp: utils.formatUnits(TotalClaimLp.toString(), '6'),
      TotalClaimReward: utils.formatEther(TotalClaimReward.toString()),
      TotalLockLpBnbAmount: utils.formatEther(TotalLockLpAmount.toString()),
      TotalHastenBnbAmount: utils.formatEther(TotalHastenBnbAmount.toString()),
      TotalRewardBnbAmount: utils.formatEther(TotalRewardBnbAmount.toString()),
      TotalEntryBnbAmount: utils.formatEther(TotalEntryBnbAmount.toString()),
      price: utils.formatUnits(_mainBnbPrice.toString(), '30'),
      payBnbPrice: utils.formatUnits(_payBnbPrice.toString(), '30'),
    },
  }
}

export const fetchLpStakingDataV2 = async (chainId: any = NETWORK_CHAIN_ID, address: string): Promise<any> => {
  const lpStakingCalls = [
    'startTradeBlock',
    'maxProfitMultiplier',
    'releaseRatio',
    'TotalBnbAmountProfitMultiplier',
    'TotalLockLpAmount',
    'TotalClaimLp',
    'TotalLockLpBnbAmount',
    'TotalHastenBnbAmount',

    'TotalRewardBnbAmount',
    'TotalEntryBnbAmount',
    '_mainBnbPrice',
    'owner',
    'TotalClaimReward',
    'isClose',
    'uniswapPair',
    '_payBnbPrice',
  ].map((method) => {
    return {
      address,
      name: method,
      params: [],
    }
  })
  // 创建合约对象
  const result = await multicall(chainId, lpStaking_abi, lpStakingCalls)

  const [
    [startTradeBlock],
    [maxProfitMultiplier],
    [releaseRatio],
    [TotalBnbAmountProfitMultiplier],
    [TotalLockLpAmount],
    [TotalClaimLp],
    [TotalLockLpBnbAmount],
    [TotalHastenBnbAmount],
    [TotalRewardBnbAmount],
    [TotalEntryBnbAmount],
    [_mainBnbPrice],
    [owner],
    [TotalClaimReward],
    [isClose],
    [uniswapPair],
    [_payBnbPrice],
  ] = result

  return {
    staking: {
      owner,
      isClose,
      uniswapPair: uniswapPair.toString(),
      startTradeBlock: Number(startTradeBlock.toString()),
      maxProfitMultiplier: Number(maxProfitMultiplier.toString()),
      releaseRatio: Number(releaseRatio.toString()),
      TotalBnbAmountProfitMultiplier: utils.formatEther(TotalBnbAmountProfitMultiplier.toString()),
      TotalLockLpAmount: utils.formatEther(TotalLockLpAmount.toString()),
      TotalClaimLp: utils.formatUnits(TotalClaimLp.toString(), '6'),
      TotalClaimReward: utils.formatEther(TotalClaimReward.toString()),
      TotalLockLpBnbAmount: utils.formatEther(TotalLockLpBnbAmount.toString()),
      TotalHastenBnbAmount: utils.formatEther(TotalHastenBnbAmount.toString()),
      TotalRewardBnbAmount: utils.formatEther(TotalRewardBnbAmount.toString()),
      TotalEntryBnbAmount: utils.formatEther(TotalEntryBnbAmount.toString()),
      price: utils.formatUnits(_mainBnbPrice.toString(), '30'),
      payBnbPrice: utils.formatUnits(_payBnbPrice.toString(), '30'),
    },
  }
}

export const fetchALLLpStakingData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const reuslt_1 = await fetchLpStakingData(chainId, getLpPeipeiStaking1Address(chainId), 'CATME', 0)
  const reuslt_2 = await fetchLpStakingData(chainId, getLpPeipeiStaking2Address(chainId), 'PEIPEI', 18)
  const reuslt_3 = await fetchLpStakingDataV2(chainId, getLpPeipeiStaking3Address(chainId))
  return {
    [getLpPeipeiStaking1Address(chainId)]: reuslt_1,
    [getLpPeipeiStaking2Address(chainId)]: reuslt_2,
    [getLpPeipeiStaking3Address(chainId)]: reuslt_3,
  }
}

export const fetchALLLpStakingUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const reuslt_1 = await fetchLpStakingUserData(chainId, account, getLpPeipeiStaking1Address(chainId), 'CATME', 0)
  const reuslt_2 = await fetchLpStakingUserData(chainId, account, getLpPeipeiStaking2Address(chainId),  'PEIPEI', 18)
  const reuslt_3 = await fetchLpStakingUserDataV2(chainId, account, getLpPeipeiStaking3Address(chainId))
  return {
    [getLpPeipeiStaking1Address(chainId)]: reuslt_1,
    [getLpPeipeiStaking2Address(chainId)]: reuslt_2,
    [getLpPeipeiStaking3Address(chainId)]: reuslt_3,
  }
}

export const fetchLpStakingUserData = async (
  chainId: any = NETWORK_CHAIN_ID,
  account: string,
  address: string,
  name: string,
  dec: number
): Promise<any> => {
  const calls = ['users', 'pendingReward', 'pendingToken', 'getUserLpData'].map((method) => {
    return {
      address,
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address,
      name: 'getLogs',
      params: [account, 0, 100],
    },
    {
      address,
      name: 'refs',
      params: [account],
    },
    {
      address,
      name: '_bnbPayPrice',
      params: [],
    },
    {
      address,
      name: '_payBnbPrice',
      params: [],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, lpStakingV2_abi.concat(Erc20_abi), calls.concat(mainCalls))
  const [users, [pendingReward], [pendingLp], getUserLpData, [getLogs], [refs], [_bnbPayPrice], [_payBnbPrice]] = result
  const [balanceOf, allowance, nftBalance, balanceBnb] = getUserLpData

  console.log(_payBnbPrice.toString())
  return {
    user: {
      lpStaking: {
        // nftBalance: Number(nftBalance.toString()),
        isApprove: allowance.toString() !== '0',
        balance: utils.formatUnits(balanceOf.toString(), dec),
        balanceBnb: utils.formatEther(nftBalance.toString()), // bnb 余额
        pendingReward: utils.formatEther(`${pendingReward.toString()}`),
        pendingLp: utils.formatUnits(pendingLp.toString(), '6'),
        amount: utils.formatUnits(users?.mainAmount.toString(), dec),
        hastenBnbAmount: utils.formatEther(users?.hastenBnbAmount.div('100')),
        bnbAmountProfitMultiplier: utils.formatEther(users?.bnbAmountProfitMultiplier.toString()),
        claimReward: utils.formatEther(users?.claimReward.toString()),
        claimLp: utils.formatUnits(users?.claimLp.toString(), '6'),
        bnbAmount: utils.formatEther(users?.amount.toString()),
        ref: refs.toString() !== ethers.constants.AddressZero && refs.toString(),

        totalPendingReward: utils.formatEther(users?.bnbAmountProfitMultiplier.sub(users?.claimReward)),
        totalPendingLp: utils.formatUnits(
          users?.bnbAmountProfitMultiplier.sub(users?.claimReward).mul(_bnbPayPrice).div(utils.parseUnits('1', '18')),
          '6'
        ),
        logs: getLogs
          .map(({ amount, types, bnbAmount, time }) => {
            return {
              amount:
                Number(types.toString()) === 0 ? utils.formatUnits(amount.toString(), '18') :  Number(types.toString()) === 1 ?  utils.formatUnits(amount.toString(), '6') : utils.formatEther(BigNumber.from(bnbAmount.toString()).div('100'))
             ,
              bnbAmount: utils.formatEther(bnbAmount.toString()),
              types:
                Number(types.toString()) === 0 ? `质押 BNB+${name}` : Number(types.toString()) === 1 ? '领取收益' : '加速释放',
              time: Number(time.toString()),
            }
          })
          .sort((a, b) => b.time - a.time),
      },
    },
  }
}

export const fetchLpStakingUserDataV2 = async (
  chainId: any = NETWORK_CHAIN_ID,
  account: string,
  address: string
): Promise<any> => {
  const calls = ['users', 'pendingReward', 'pendingToken', 'getUserLpData'].map((method) => {
    return {
      address,
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address,
      name: 'getLogs',
      params: [account, 0, 100],
    },
    {
      address,
      name: 'refs',
      params: [account],
    },
    {
      address,
      name: '_bnbPayPrice',
      params: [],
    },
    {
      address,
      name: '_payBnbPrice',
      params: [],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, lpStaking_abi.concat(Erc20_abi), calls.concat(mainCalls))
  const [users, [pendingReward], [pendingLp], getUserLpData, [getLogs], [refs], [_bnbPayPrice], [_payBnbPrice]] = result
  const [balanceOf, allowance, nftBalance, balanceBnb] = getUserLpData

  console.log(_payBnbPrice.toString())
  return {
    user: {
      lpStaking: {
        nftBalance: Number(nftBalance.toString()),
        isApprove: allowance.toString() !== '0',
        balance: utils.formatEther(balanceOf.toString()),
        balanceBnb: utils.formatEther(balanceBnb.toString()),
        pendingReward: utils.formatEther(`${pendingReward.toString()}`),
        pendingLp: utils.formatUnits(pendingLp.toString(), '6'),
        amount: utils.formatEther(users?.amount.toString()),
        hastenBnbAmount: utils.formatEther(users?.hastenBnbAmount.div('100')),
        bnbAmountProfitMultiplier: utils.formatEther(users?.bnbAmountProfitMultiplier.toString()),
        claimReward: utils.formatEther(users?.claimReward.toString()),
        claimLp: utils.formatUnits(users?.claimLp.toString(), '6'),
        bnbAmount: utils.formatEther(users?.bnbAmount.toString()),
        ref: refs.toString() !== ethers.constants.AddressZero && refs.toString(),

        totalPendingReward: utils.formatEther(users?.bnbAmountProfitMultiplier.sub(users?.claimReward)),
        totalPendingLp: utils.formatUnits(
          users?.bnbAmountProfitMultiplier.sub(users?.claimReward).mul(_bnbPayPrice).div(utils.parseUnits('1', '18'))
          ,'6'
        ),
        logs: getLogs
          .map(({ amount, types, bnbAmount, time }) => {
            return {
              amount:
                Number(types.toString()) === 0 ? utils.formatUnits(amount.toString(), '18') :  Number(types.toString()) === 1 ?  utils.formatUnits(amount.toString(), '6') : utils.formatEther(BigNumber.from(bnbAmount.toString()).div('100'))
             ,
              bnbAmount: utils.formatEther(bnbAmount.toString()),
              types:
                Number(types.toString()) === 0 ? `质押 LP` : Number(types.toString()) === 1 ? '领取收益' : '加速释放',
              time: Number(time.toString()),
            }
          })
          .sort((a, b) => b.time - a.time),
      },
    },
  }
}

interface StakingState {
  ref?: string
  data?: { [key: string]: { user?: any; staking?: any } }
}

const initialState: StakingState = {}

export const LpStakingSlice = createSlice({
  name: 'lpStaking',
  initialState,
  reducers: {
    setLpStakingData: (state, action) => {
      state.data = Object.keys(action.payload).reduce<{ [address: string]: any }>((memo, address, i) => {
        memo[address] = {
          ...(state.data?.[address] || {}),
          staking: action.payload[address].staking,
        }
        return memo
      }, {})

      // console.log(action)
    },
    setLpStakingUserData: (state, action) => {
      console.log(Object.keys(action.payload))
      state.data = Object.keys(action.payload).reduce<{ [address: string]: any }>((memo, address, i) => {
        memo[address] = {
          ...(state.data?.[address] || {}),
          user: action.payload[address].user,
        }
        return memo
      }, {})
      const refAddress = Object.keys(action.payload)
        .map((key) => action.payload[key].user.lpStaking?.ref)
        .find((ref) => !!ref)
      state.ref = refAddress || state.ref
    },
    setLpRef: (state, action) => {
      state.ref = action.payload
    },
  },
})

// Actions
export const { setLpStakingData, setLpStakingUserData, setLpRef } = LpStakingSlice.actions

export default LpStakingSlice.reducer
